<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service">服务币管理</div>
    </div>
    <div class="flex" style="padding: 10px 0px">
      <div class="size_24">
        服务币余额:
        <span style="color: #ff6618">{{ currencymoney === "" ? 0 : currencymoney }}个</span>
      </div>
      <div class="size_24 marl_30" style="color: #c065e7" @click="setwithdrawDeposit()">
        提现账号设置
      </div>
      <div class="size_24 marl_30" style="color: #fb1010" @click="withdrawDeposit()">
        申请提现
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="180"> </el-table-column>
      <el-table-column prop="contacts" label="名字" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="withdrawal_bank_number " label="开户行卡号" min-width="180" align="center">
        <template slot-scope="props">
          <div v-if="props.row.withdrawal_bank_number">
            {{ props.row.withdrawal_bank_number }}
          </div>
          <div v-else>{{ props.row.bank_number }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="提现服务币数量" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="operation" label="审核状态" min-width="180" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 1" style="color: #c065e7">待审核</div>
          <div v-else="scope.row.status === 2" style="color: #FF6618">已通过</div>
          <div v-else="scope.row.status === 3" style="color: #FC3030">未通过</div>
          <!-- <el-button type="text">查看申报详情</el-button> -->
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="提交时间" min-width="180" align="center">
      </el-table-column>
    </el-table>

    <el-pagination v-if="tableData.length != 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
    <!-- 提现 -->
    <el-dialog title="申请提现" :visible.sync="withdrawDepositlogin" width="600px" center>
      <div>
        <div>
          服务币余额：<span style="color: #ff6618">{{ currencymoney === "" ? 0 : currencymoney }}个</span>

          <el-form ref="form" :model="drawDepos" label-width="80px">
            <el-form-item label="本次提现服务币数量（个）" required>
              <el-input v-model="drawDepos.num"></el-input>
            </el-form-item>
          </el-form>
          <div class="flex" style="margin-top: 300px">
            <div style="
                width: 137px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #ff6618;
                border-radius: 10px;
                color: #fff;
              " @click="addmoney()">
              确认提现
            </div>
            <div class="marl_30" style="
                width: 137px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #cfcfcf;
                border-radius: 10px;
                color: #fff;
              " @click="withdrawDepositlogin = false">
              取消
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- //设置提现账号 -->
    <el-dialog title="提现账号设置" :visible.sync="setwithdrawDepositlogin" width="600px" center>
      <div>
        <el-form ref="form" :model="setdrawDepos" label-width="80px">
          <el-form-item label="持卡人姓名" required>
            <el-input v-model="setdrawDepos.withdrawal_name"></el-input>
          </el-form-item>
          <el-form-item label="开户行" required>
            <el-input v-model="setdrawDepos.withdrawal_bank"></el-input>
          </el-form-item>
          <el-form-item label="开户行账号" required>
            <el-input v-model="setdrawDepos.withdrawal_bank_number"></el-input>
          </el-form-item>
        </el-form>
        <div class="size_18" style="color: #ec3d3d">
          注：请输入正确的银行卡信息，如因用户填写错误的银行卡导致转账
          错误，本平台一概不负责。
        </div>
      </div>
      <div class="flex mat_30">
        <div style="
            width: 137px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #ff6618;
            border-radius: 10px;
            color: #fff;
          " @click="save">
          确认保存
        </div>
        <div class="marl_30" style="
            width: 137px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #cfcfcf;
            border-radius: 10px;
            color: #fff;
          " @click="setwithdrawDepositlogin = false">
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      allTableData: [],
      tableData: [],
      drawDepos: { num: "" },
      currencymoney: "",
      setdrawDepos: {
        withdrawal_name: "",
        withdrawal_bank: "",
        count: "",
      },
      total: "",
      withdrawDepositlogin: false, //提现
      setwithdrawDepositlogin: false, //设置提现
    };
  },
  created() {
    this.postapplylist();
    this.postcurrency();

  },
  methods: {
    addmoney() {
      if (this.drawDepos.num == '0') {
        this.$message({
          message: '提现金额不能为零',
          type: "warning",
          duration: 1500,
        });
      } else {
        
        this.$confirm('是否确认提现, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.apply_add()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });


      }

    },
    apply_add(){
      let data = {
          token: localStorage.eleToken,
          num: this.drawDepos.num,
        };
        console.log(data, "data");
        this.$post("apply_add", data).then((res) => {
          // this.$message({
          //   message: res.msg,
          //   type: "success",
          //   duration: 1500,
          // });
          this.postcurrency();
          this.withdrawDepositlogin = false;
        });
    },
    //查看服务币
    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("commissioner_currency", data).then((res) => {
        this.currencymoney = res.result.currency;
        console.log(this.currencymoney, " this.currencymoney ");
      });
    },

    lookcount() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("commissioner_withdrawal", data).then((res) => {
        this.setdrawDepos = res.result
        console.log(res,'res')
      });
    },

    

    //提交设置账号
    save() {
      let data = {
        token: localStorage.eleToken,
        withdrawal_name: this.setdrawDepos.withdrawal_name,
        withdrawal_bank: this.setdrawDepos.withdrawal_bank,
        withdrawal_bank_number: this.setdrawDepos.withdrawal_bank_number,
      };

      this.$post("commissioner_setting", data).then((res) => {
        console.log(res, "res");
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.setwithdrawDepositlogin = false;
      });
    },
    setwithdrawDeposit() {
      this.lookcount()
      this.setwithdrawDepositlogin = true;
      
    },
    withdrawDeposit() {
      this.withdrawDepositlogin = true;
    },

    postapplylist() {
      let data = {
        token: localStorage.eleToken,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$post("apply_lst", data).then((res) => {
        this.allTableData = res.result;
        this.total = res.withdrawal_bank_number;
        this.setPaginations();
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.sta = Number(tab.index) + 1;
      this.getorderlist();
      this.getcatelist();
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

    //重置分页
    setPaginations() {
      //初始化
      this.paginations.total = this.allTableData.length;
      this.paginations.page_index = 1;
      this.paginations.page_size = 10;
      //设置默认的分页数据
      this.tableData = this.allTableData.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep .el-form-item__content {
  margin-left: auto !important;
}

::v-deep .el-form-item__label {
  width: auto !important;
}
</style>
